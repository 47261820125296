<template>
  <div style="height: calc(100vh - 160px)">
    <!-- 
      https://dxydev-8099-mc01.dev.sims-cn.com/taskApp/pxt/created/CTI000PLZ88EKMQM9?type=copy11&checkUrl=1&isEdit=1&styletype=styletype1
      /taskApp/pxt/created/CTI000XL8SZDT2N3XA?isEdit=1
      src="https://dxydev-8099-mc01.dev.sims-cn.com/taskApp/pxt/created/CTI000PLZ88EKMQM9?type=copy11&checkUrl=0&isEdit=1&styletype="
      src="https://dxydev-8099-mc01.dev.sims-cn.com/taskApp/pxt/created/CTI000XL8SZDT2N3XA?isEdit=1"
    -->
    <iframe
      src="https://dxydev-8099-mc01.dev.sims-cn.com/taskApp/pxt/created/CTI000XL8SZDT2N3XA?isEdit=1"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
  <!-- <div>
    <iframe
      id="iframe"
      src="https://dxydev-8081-mc01.dev.sims-cn.com/taskDetailsForIframe"
      frameborder="0"
      width="390px"
      height="840px"
      style="border: 1px solid #ccc"
    ></iframe>
  </div> -->
</template>
<script>
import mock from "@/mock/mock.js";
export default {
  mounted() {
    // 接受子页面传来的信息
    window.addEventListener(
      "message",
      function (event) {
        // console.log(event);
        // 可判断消息来源是否为子页面 event.origin

        // 点击取消或者返回的回调
        if (event.data === "clickBack") {
          console.log("点击了返回或者取消按钮");
        }

        //需要在iframe加载好了之后再发送信息给子页面
        if (event.data === "ready") {
          var iframe = document.getElementById("iframe");

          //替换这个数据就可以了
          let data = mock.data4;

          if (iframe.contentWindow) {
            console.log("主页面已发送");
            iframe.contentWindow.postMessage(
              {
                type: "taskDetail",
                data,
              },
              "*"
            );
          }
        }
      },
      false
    );
  },
};
</script>
