let data = {
  data1: [
    {
      area: "东城区1",
      cusLabels: "旧的4,我的,旧的3,旧的1,旧的2,我的1",
      address: "甘井子区大连市辽宁省1",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "s4545PUB3MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区2",
      address: "甘井子区大连市辽宁省2",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB00se45s450857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省3",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3se4545MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省4",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB0s45s4500857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省5",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB0s454500857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省6",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB0008s45ss57398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省7",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMBs45ss000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省8",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUse45sB3MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省9",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB00s4450857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省10",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3sdfgMEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
  ],
  data2: [
    {
      area: "东城区222222",
      address: "甘井子区大连市辽宁省11",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "P32453465UB3MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省12",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "P234234UB3MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省13",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3453MEMB000857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省14",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB00999990857398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省15",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB000857399998SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省16",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB00085799398SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省17",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB0008573998SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省18",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K210101100514744472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K210101162336350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB00085735898SY00080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省19",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB000857398SY004080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省20",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQZ4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 483,
      terminalCode: "PUB3MEMB0008573918SY0008032",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
  ],
  data3: [
    {
      area: "东城区",
      address: "甘井子区大连市辽宁省21",
      city: "北京市",
      taskDetailsCode: "CTD0007YQNC2FDBQ123Z4",
      platformAuditStatus: 0,
      taskInfoId: 175469,
      receiveStatus: 0,
      taskPort: "PXTZT",
      version: 35,
      taskPackageCode: "CTG0007JPK6J6123BA",
      updater: "uTHCG0K9DBBpSZplxv9Zj123754OxxSfDh6",
      maxReceiveNum: 1,
      tenCode: "test",
      terminalName: "166001ooo",
      planNum: 1,
      province: "北京市",
      person: [
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010110051444472009",
          phone: "",
          name: "赵友朋",
          id: 122,
          taskDetailsId: 483,
          version: 0,
          userCode: "test2",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
        {
          createdAt: "2022-09-28 15:41:29",
          phoneEncrypt: "K21010116233350570969",
          phone: "",
          name: "赵义朋",
          id: 123,
          taskDetailsId: 483,
          version: 0,
          userCode: "test3",
          status: 1,
          updatedAt: "2022-09-28 15:41:29",
        },
      ],
      creater: "uTHCG0K9DBBpSZplxv9Zj754OxxSfDh6",
      id: 48113,
      terminalCode: "PUB3MEMB000857398S2312300080",
      status: 1,
      updatedAt: "2022-09-28 17:26:13",
    },
  ],
  data4: {
    "success": true,
    "data": {
      "itemList": [
        {
          "title": "第1题 选23会提前结束 选C显示第三题",
          "taskSubId": null,
          "code": "ITEM3PN5WUR9C75D",
          "sort": 1,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 2,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 1241809,
              "taskSubItemId": 3995168,
              "code": "OPTION3P6XRQZ6P75D",
              "optionNo": null,
              "title": "1",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241810,
              "taskSubItemId": 3995168,
              "code": "OPTION3PJEBA67Z75D",
              "optionNo": null,
              "title": "2",
              "sort": 2,
              "tips": null,
              "earlyEnd": true,
              "normalEnd": false
            },
            {
              "id": 1241811,
              "taskSubItemId": 3995168,
              "code": "OPTION3P2876E4C85D",
              "optionNo": null,
              "title": "3",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241812,
              "taskSubItemId": 3995168,
              "code": "OPTION3PEPQPJ4N85D",
              "optionNo": null,
              "title": "4",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": true,
          "normalEnd": false
        },
        {
          "title": "第2题 未选中1正常结束 选B显示第三题",
          "taskSubId": null,
          "code": "ITEM3PS6A9P4Y85D",
          "sort": 2,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 2,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 1241813,
              "taskSubItemId": 3995169,
              "code": "code1",
              "optionNo": null,
              "title": "4",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,//用不到了 因为存在不选中多个的情况
              "normalEnd": true//用不到了 因为存在不选中多个的情况
            },
            {
              "id": 1241814,
              "taskSubItemId": 3995169,
              "code": "code2",
              "optionNo": null,
              "title": "5",
              "sort": 2,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241815,
              "taskSubItemId": 3995169,
              "code": "OPTION3PGVWSY4M95D",
              "optionNo": null,
              "title": "6",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241816,
              "taskSubItemId": 3995169,
              "code": "OPTION3PUCGC55X95D",
              "optionNo": null,
              "title": "7",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": true
        },
        {
          "title": "第3题多选 选A显示第5题 选B正常结束",
          "taskSubId": null,
          "code": "ITEM3PYXTVAJW65D",
          "sort": 3,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 2,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 1241823,
              "taskSubItemId": 3995169,
              "code": "OPTION3PAY55XZA951",
              "optionNo": null,
              "title": "选项1",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241824,
              "taskSubItemId": 3995169,
              "code": "OPTION3P8QPKXZB952",
              "optionNo": null,
              "title": "选项2",
              "sort": 2,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241825,
              "taskSubItemId": 3995169,
              "code": "OPTION3PGVWSY4M953",
              "optionNo": null,
              "title": "选项3",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241826,
              "taskSubItemId": 3995169,
              "code": "OPTION3PUCGC55X954",
              "optionNo": null,
              "title": "选项4",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": true
        },
        {
          "title": "第4题 不选AB显示第5题和第6题",
          "taskSubId": null,
          "code": "ITEM3PS6A9P4Y852",
          "sort": 4,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 2,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 1241123,
              "taskSubItemId": 3995169,
              "code": "OPTION3PAY55XZA952",
              "optionNo": null,
              "title": "选项1",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241124,
              "taskSubItemId": 3995169,
              "code": "OPTION3P8QPKXZB953",
              "optionNo": null,
              "title": "选项2",
              "sort": 2,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241125,
              "taskSubItemId": 3995169,
              "code": "OPTION3PGVWSY4M954",
              "optionNo": null,
              "title": "选项3",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1241126,
              "taskSubItemId": 3995169,
              "code": "OPTION3PUCGC55X955",
              "optionNo": null,
              "title": "选项4",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": false
        },
        {
          "title": '第五题请上传照片',
          "taskSubId": null,
          "code": 'ITEMTL82C6RA37Bzzz',
          "sort": 22,
          "sampleText": '请上传2-5张照片',
          "sampleImg": 'https://img01.yzcdn.cn/vant/sand.jpg',
          "itemType": 3,
          "itemRegex": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": null,
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": '',
          "imgMinCount": 2,
          "imgMaxCount": 5,
          "itemImgs": [],
          "tenCode": 'tCLXZ0GGDuwI8dFLgHtLJbXL'
        },
        {
          "title": "第6题单选 选A显示第8题",
          "taskSubId": null,
          "code": "ITEM3PS6A9P4Y856",
          "sort": 16,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 1,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 1246163,
              "taskSubItemId": 3995169,
              "code": "OPTION3PAY55XZA956",
              "optionNo": null,
              "title": "选项1",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1246124,
              "taskSubItemId": 3995169,
              "code": "OPTION3P8QPKXZB963",
              "optionNo": null,
              "title": "选项2",
              "sort": 2,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1246125,
              "taskSubItemId": 3995169,
              "code": "OPTION3PGVWSY4M654",
              "optionNo": null,
              "title": "选项3",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 1246126,
              "taskSubItemId": 3995169,
              "code": "OPTION3PUCGC556955",
              "optionNo": null,
              "title": "选项4",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": false
        },
        {
          "title": "第7题问答 一直显示",
          "taskSubId": null,
          "code": "ITEM7",
          "sort": 7,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 0,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": null,
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": false
        },
        {
          "title": "第8题单选",
          "taskSubId": null,
          "code": "ITEM8",
          "sort": 88,
          "sampleText": null,
          "sampleImg": null,
          "itemType": 1,
          "itemRegex": null,
          "imgMinCount": null,
          "imgMaxCount": null,
          "itemNo": null,
          "tips": null,
          "subTaskListOptionDetailDto": [
            {
              "id": 881,
              "taskSubItemId": 3995169,
              "code": "ITEM8Code1",
              "optionNo": null,
              "title": "选项1",
              "sort": 1,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 882,
              "taskSubItemId": 3995169,
              "code": "ITEM8Code2",
              "optionNo": null,
              "title": "选项2",
              "sort": 2,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 883,
              "taskSubItemId": 3995169,
              "code": "ITEM8Code3",
              "optionNo": null,
              "title": "选项3",
              "sort": 3,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            },
            {
              "id": 884,
              "taskSubItemId": 3995169,
              "code": "ITEM8Code4",
              "optionNo": null,
              "title": "选项4",
              "sort": 4,
              "tips": null,
              "earlyEnd": false,
              "normalEnd": false
            }
          ],
          "itemAnswer": null,
          "itemOptionCode": null,
          "itemImg": null,
          "itemImgs": null,
          "itmeImgs": null,
          "tenCode": "tCZFb0KALtBGgjW4FtGT99Ep",
          "earlyEnd": false,
          "normalEnd": false
        },
      ],
      "logic": [
        {//选23 提前结束
          "ITEM3PN5WUR9C75D": {
            "optionalCodeList": [
              "OPTION3P2876E4C85D", "OPTION3PJEBA67Z75D"
            ],//选项23 
            "logicItemCode": null,
            "type": "selected",
            "isFinish": "earlyEnd"
          }
        },
        {//第二题未选中1正常结束
          "ITEM3PS6A9P4Y85D": {
            "optionalCodeList": [
              "code1",
            ],
            "logicItemCode": null,
            "type": "unselected",
            "isFinish": "normalEnd"
          }
        },
        {//第一题选C显示第三题
          "ITEM3PN5WUR9C75D": {
            "optionalCodeList": [
              "OPTION3P2876E4C85D"
            ],
            "logicItemCode": [
              "ITEM3PYXTVAJW65D" //第三题
            ],
            "type": "selected",
            "isFinish": null
          }
        },
        {//第2题选B显示第三题
          "ITEM3PS6A9P4Y85D": {
            "optionalCodeList": [
              "code2"
            ],
            "logicItemCode": [
              "ITEM3PYXTVAJW65D" //第三题
            ],
            "type": "selected",
            "isFinish": null
          }
        },
        {//第3题选A显示第5题
          "ITEM3PYXTVAJW65D": {
            "optionalCodeList": [
              "OPTION3PAY55XZA951"
            ],
            "logicItemCode": [
              "ITEMTL82C6RA37Bzzz"
            ],
            "type": "selected",
            "isFinish": null
          }
        },
        {//第3题选A显示第5题
          "ITEM3PYXTVAJW65D": {
            "optionalCodeList": [
              "OPTION3PAY55XZA951"
            ],
            "logicItemCode": [
              "ITEMTL82C6RA37Bzzz"
            ],
            "type": "selected",
            "isFinish": null
          }
        },
        {//第3题选B正常结束
          "ITEM3PYXTVAJW65D": {
            "optionalCodeList": [
              "OPTION3P8QPKXZB952"
            ],
            "logicItemCode": null,
            "type": "selected",
            "isFinish": "normalEnd"
          }
        },
        {//第4题不选AB显示第5题和第6题
          "ITEM3PS6A9P4Y852": {
            "optionalCodeList": [
              "OPTION3PAY55XZA952", "OPTION3P8QPKXZB953"
            ],
            "logicItemCode": ["ITEMTL82C6RA37Bzzz", "ITEM3PS6A9P4Y856"],
            "type": "unselected",
            "isFinish": null
          }
        },
        {//第6题选A显示第8题
          "ITEM3PS6A9P4Y856": {
            "optionalCodeList": [
              "OPTION3PAY55XZA956"
            ],
            "logicItemCode": ["ITEM8"],
            "type": "selected",
            "isFinish": null
          }
        },
      ]
    },
    "traceId": "ae398a2e5a65ede1b786aef02cec8ef4"
  }
};
export default data;
